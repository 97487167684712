import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

export const AuthenticationGuard = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className='page-layout'>
        <div>Redirecting...</div>
      </div>
    ),
  });

  return <Component {...args} />;
};
