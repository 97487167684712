import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import LogoutButton from "../components/LogoutButton";
import FormContext from "../contexts/FormContext";
import axios from "axios";
import { API_BASE_URL } from "../App";
const NavBar = () => {
  const { setForms } = useContext(FormContext);
  const handleFetchForms = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/`);
      setForms(response.data);
    } catch (error) {
      console.error("The was a problem fetching forms:", error);
    }
  };
  return (
    <>
      <NavLink className='sjs-nav-button' to='/' onClick={handleFetchForms}>
        <span>Home</span>
      </NavLink>
      {/* <NavLink className='sjs-nav-button' to='/about'>
        <span>About</span>
      </NavLink> */}
      <LogoutButton />
    </>
  );
};

export default NavBar;
