import React, { useContext, useEffect, useMemo } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { Action, setLicenseKey } from "survey-core";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.css";
import FormContext from "../contexts/FormContext";
import endUseDocumentationFile from "./../End-User-Documentation.pdf";
const SURVEYJS_DEV_KEY = process.env.REACT_APP_SURVEYJS_KEY;
const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const questionTypes = [
  "boolean",
  "checkbox",
  "comment",
  "dropdown",
  "tagbox",
  "expression",
  "html",
  "matrix",
  "matrixdropdown",
  "matrixdynamic",
  "multipletext",
  "panel",
  "paneldynamic",
  "radiogroup",
  "rating",
  "ranking",
  "signaturepad",
  "text",
];

const Editor = () => {
  const { id } = useParams();
  const { forms } = useContext(FormContext);
  setLicenseKey(SURVEYJS_DEV_KEY);
  const saveFormJson = async (url, json, saveNo, callback) => {
    try {
      const response = await axios.put(url, json);
      if (!!response && !!response.data) {
        callback(saveNo, true);
      } else {
        callback(saveNo, false);
      }
    } catch (error) {
      console.error("Error saving form:", error);
      callback(saveNo, false);
    }
  };

  const creator = useMemo(() => {
    const options = {
      showTranslationTab: true,
      showLogicTab: true,
      questionTypes,
    };
    return new SurveyCreator(options);
  }, []);
  creator.isAutoSave = true;

  creator.toolbar.actions.push(
    new Action({
      id: "user-guide",
      visible: true,
      title: "User Guide",
      action: function () {
        const win = window.open(endUseDocumentationFile, "_blank");
        win.focus();
      },
    })
  );
  // creator.showSaveButton = true;
  creator.saveSurveyFunc = (saveNo, callback) => {
    const url = `${API_BASE_URL}/edit/${id}`;
    saveFormJson(url, creator.JSON, saveNo, callback);
  };

  useEffect(() => {
    const currentForm = forms.find((s) => s["_id"] === id);
    if (currentForm) {
      creator.JSON = currentForm;
    }
  }, [id, forms]);

  return <SurveyCreatorComponent creator={creator} />;
};

export default Editor;
