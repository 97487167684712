import LoginButton from "../components/LoginButton";

const LoginPage = () => {
  return (
    <div className='loginPageContainer' style={{ textAlign: "center" }}>
      <h1>Form Creator Login</h1>
      <LoginButton />
    </div>
  );
};

export default LoginPage;
