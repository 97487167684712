import React from "react";
import Forms from "../components/Forms";

const LoadingIndicator = () => <div>Loading Forms...</div>;
const Home = ({ args: loadingForms }) => {
  return (
    <div className='sjs-client-app__content--forms-list'>
      <h1>Forms</h1>
      {!!loadingForms ? <LoadingIndicator /> : <Forms />}
    </div>
  );
};

export default Home;
