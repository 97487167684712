import React, { useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Forms.css";
import FormContext from "../contexts/FormContext";
import Modal from "./Modal.js";

const Forms = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const { forms, setForms } = useContext(FormContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [formToDelete, setFormToDelete] = useState(null);

  const handleRemoveForm = async () => {
    if (!formToDelete) return;
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/delete/${formToDelete["_id"]}`
      );
      setForms(data);
    } catch (error) {
      console.log("Error deleting form:", error);
    }
  };

  const handleOpenDialogAlert = (form) => {
    setOpenDialog(!openDialog);
    setFormToDelete(form);
  };

  const handleDialogClose = (e) => {
    if (e.target.name === "delete-button") {
      handleRemoveForm();
    }
    setOpenDialog(!openDialog);
  };

  const handleEditForm = (id) => {
    navigate(`/edit/${id}`);
  };

  const handleCreateForm = async () => {
    try {
      const auth0UserId = user?.sub;
      const {
        data: { insertedId },
      } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/create/${auth0UserId}`
      );
      navigate(`/create/${insertedId}`);
    } catch (error) {
      console.error("Error creating new form:", error);
    }
  };

  return (
    <>
      <table className='sjs-forms-list'>
        <tbody>
          {!!forms &&
            forms.length > 0 &&
            forms.map((form) => (
              <tr key={form["_id"]} className='sjs-forms-list__row'>
                <td>
                  <span>{form.title}</span>
                </td>
                <td>
                  <span
                    className='sjs-button sjs-edit-btn'
                    onClick={() => handleEditForm(form["_id"])}
                  >
                    Edit
                  </span>
                  <span
                    className='sjs-button sjs-remove-btn'
                    onClick={() => handleOpenDialogAlert(form)}
                  >
                    Remove
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className='sjs-forms-list__footer'>
        <span
          className='sjs-button sjs-add-btn'
          title='increment'
          onClick={handleCreateForm}
        >
          Add Form
        </span>
      </div>
      {
        <Modal show={openDialog} onClose={handleDialogClose}>
          <h2>Delete Form</h2>
          <p>{`${formToDelete?.title}`}</p>
          <button
            className='sjs-button sjs-edit-btn modal-cancel-button'
            name='cancel-button'
            onClick={(e) => handleDialogClose(e)}
          >
            Cancel
          </button>
          <button
            className='sjs-button sjs-remove-btn modal-delete-button'
            name='delete-button'
            onClick={(e) => handleDialogClose(e)}
          >
            Delete
          </button>
        </Modal>
      }
    </>
  );
};

export default Forms;
