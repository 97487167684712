import React, { useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import CallbackPage from './pages/CallbackPage';
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import { AuthenticationGuard } from './authentication-guard';
import NavBar from './components/NavBar';
import logo from './2023.11.27logosm2.png';
import FormContext from './contexts/FormContext';
import Editor from './components/Editor';

export const API_BASE_URL = process.env.REACT_APP_BASE_URL;

function App() {
  const { isLoading, isAuthenticated } = useAuth0();
  const [forms, setForms] = useState([]);
  const [error, setError] = useState(null);
  const [loadingForms, setLoadingForms] = useState(false);
  const contextValue = useMemo(() => {
    let sortedForms = [];
    if (forms.length > 0) {
      sortedForms = [...forms].sort((a, b) =>
        (a.title || '').localeCompare(b.title || '', 'en', {
          ignorePunctuation: true,
        })
      );
    }
    return {
      forms: sortedForms,
      setForms,
    };
  }, [forms]);

  useEffect(() => {
    let isMounted = true; // Flag to prevent state updates if the component is unmounted

    const fetchForms = async () => {
      setLoadingForms(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/`);
        if (isMounted) {
          setForms(response.data);
        }
      } catch (error) {
        console.error('There was an error fetching forms', error);
        if (isMounted) {
          setError(error);
        }
      }
      setLoadingForms(false);
    };

    fetchForms();

    return () => {
      isMounted = false; // Set flag to false when the component unmounts
    };
  }, []); // Empty dependency array ensures this effect runs once on mount only

  if (isLoading || !forms) {
    return <div className="page-layout">...Loading</div>;
  }

  if (error && isAuthenticated) {
    // Render an error message or component when there's an error
    return (
      <div className="page-layout">Error fetching forms: {error.message}</div>
    );
  }

  return (
    <FormContext.Provider value={contextValue}>
      <Router>
        {isAuthenticated && (
          <img src={logo} className="form-creator-logo" alt="logo" />
        )}
        <NavBar />
        <Routes>
          <Route
            path="/"
            element={
              <AuthenticationGuard
                loadingForms={loadingForms}
                component={Home}
              />
            }
          />
          <Route
            path="/edit/:id"
            element={<AuthenticationGuard component={Editor} />}
          ></Route>
          <Route
            path="/create/:id"
            element={<AuthenticationGuard component={Editor} />}
          />
          {/* <Route path='/about' element={<About />} /> */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/callback" element={<CallbackPage />} />
        </Routes>
      </Router>
    </FormContext.Provider>
  );
}

export default App;
