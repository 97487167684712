import React from "react";
import NavBar from "../components/NavBar";
const CallbackPage = () => {
  return (
    <div className='page-layout'>
      <NavBar />
      <div className='page-layout__content' />
    </div>
  );
};

export default CallbackPage;
